body {
  margin: 0;
  font-family:"Inter";
  background: #F4F5FA !important;
}
.otp-input {
  height: 35px!important;
  width: 32px!important;
  background: white !important;
  border-radius: 6px!important;
  border: 1px solid #969696!important;
}
.otp-input:disabled {
  border: 1px solid #969696!important;
  background: rgb(150 150 150 / 10%)!important;
  outline: none !important;
}  
.otp-input:focus {
  border: 1px solid #969696!important;
  background: white !important;
  outline: none !important;
}  

  .linkStyle {
  /* color: black; Link color */
  transition: color 0.3s ease; /* Add a smooth color transition on hover */
}

/* Hover State */
.linkStyle:hover {
  text-decoration: underline; /* Remove underline */
  color: black; /* Change color on hover */
}

/* Visited State */
.linkStyle:visited {
  color: #793ED6; /* Color for visited links */
}

/* Active State (when clicked) */
.linkStyle:active {
  color: #793ED6; /* Color when link is active/clicked */
}
.linkStyle-always {
  text-decoration: underline; /* Remove underline */
  color: #793ED6  !important; /* Color for visited links */
  cursor: pointer;
}
.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline{
   background: rgba(200, 200, 200, 0.3) !important;

}

.dashed-border {
  position: relative;
  /* margin:4px 0; */
  height:1px;
}

.dashed-border::after {
content: "";
position: absolute;
top: 0; /* Adjust the distance between the top of the element and the double dashed line as needed */
left: 0;
width: 100%;
height: 0.9px; /* Adjust the space between the two lines (doubled) */
background: repeating-linear-gradient(
  to right,
  #ddd,
  #ddd 10px, /* Adjust the space between the two lines (doubled) */
  transparent 10px,
  transparent 20px /* Adjust the space between the two lines (doubled) */
);
}
.scrollBluePrimarythin::-webkit-scrollbar {
  width: 5px;
  border: 2px solid rgb(232, 227, 227);

  background-color: rgb(232, 227, 227);
}

.scrollBluePrimarythin::-webkit-scrollbar-thumb {
  background-color: #838383;
  background-clip: padding-box;
  border-radius: 15px;
  height: 1px;
}
.scrollBluePrimarythin::-webkit-scrollbar-thumb:hover {
  background: #838383;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-text-fill-color: #333 !important;
  background-color: transparent !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none!important;
  margin: 0!important;
}
.ck-content{
  min-height: 100px !important;
  max-height: 150px !important;
  overflow-y: auto !important;
}

.wrs_editor .wrs_tickContainer{
  max-height: 1px !important;
  visibility: hidden !important;
}