/* CustomScrollbar.css */
.table-container::-webkit-scrollbar {
    width: 5px;
    height: 6px
  }
  
  .table-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .table-container::-webkit-scrollbar-thumb {
    background: rgba(75, 135, 197, 0.9);
    border-radius: 8px;
  }
  
  .table-container::-webkit-scrollbar-thumb:hover {
    background: rgba(75, 135, 197, 0.9);
  }
  
  .table-container {
    scrollbar-width: thin;
    scrollbar-color: rgba(75, 135, 197, 0.9) #f1f1f1;
  }